exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-api-integrations-js": () => import("./../../../src/pages/api-integrations.js" /* webpackChunkName: "component---src-pages-api-integrations-js" */),
  "component---src-pages-brochures-js": () => import("./../../../src/pages/brochures.js" /* webpackChunkName: "component---src-pages-brochures-js" */),
  "component---src-pages-compare-js": () => import("./../../../src/pages/compare.js" /* webpackChunkName: "component---src-pages-compare-js" */),
  "component---src-pages-contact-us-js": () => import("./../../../src/pages/contact-us.js" /* webpackChunkName: "component---src-pages-contact-us-js" */),
  "component---src-pages-fintech-index-js": () => import("./../../../src/pages/fintech/index.js" /* webpackChunkName: "component---src-pages-fintech-index-js" */),
  "component---src-pages-franchises-js": () => import("./../../../src/pages/franchises.js" /* webpackChunkName: "component---src-pages-franchises-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-industries-js": () => import("./../../../src/pages/industries.js" /* webpackChunkName: "component---src-pages-industries-js" */),
  "component---src-pages-lite-js": () => import("./../../../src/pages/lite.js" /* webpackChunkName: "component---src-pages-lite-js" */),
  "component---src-pages-pricing-js": () => import("./../../../src/pages/pricing.js" /* webpackChunkName: "component---src-pages-pricing-js" */),
  "component---src-pages-professional-services-js": () => import("./../../../src/pages/professional-services.js" /* webpackChunkName: "component---src-pages-professional-services-js" */),
  "component---src-pages-roi-calculator-js": () => import("./../../../src/pages/roi-calculator.js" /* webpackChunkName: "component---src-pages-roi-calculator-js" */),
  "component---src-pages-tech-support-js": () => import("./../../../src/pages/tech-support.js" /* webpackChunkName: "component---src-pages-tech-support-js" */),
  "component---src-pages-templates-js": () => import("./../../../src/pages/templates.js" /* webpackChunkName: "component---src-pages-templates-js" */),
  "component---src-pages-uk-js": () => import("./../../../src/pages/uk.js" /* webpackChunkName: "component---src-pages-uk-js" */),
  "component---src-pages-why-realgreen-js": () => import("./../../../src/pages/why-realgreen.js" /* webpackChunkName: "component---src-pages-why-realgreen-js" */),
  "component---src-templates-comparisons-js": () => import("./../../../src/templates/Comparisons.js" /* webpackChunkName: "component---src-templates-comparisons-js" */),
  "component---src-templates-industry-js": () => import("./../../../src/templates/Industry.js" /* webpackChunkName: "component---src-templates-industry-js" */),
  "component---src-templates-product-feature-js": () => import("./../../../src/templates/ProductFeature.js" /* webpackChunkName: "component---src-templates-product-feature-js" */),
  "component---src-templates-template-js": () => import("./../../../src/templates/Template.js" /* webpackChunkName: "component---src-templates-template-js" */),
  "component---src-templates-thank-you-js": () => import("./../../../src/templates/ThankYou.js" /* webpackChunkName: "component---src-templates-thank-you-js" */)
}

